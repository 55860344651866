import React, { useState } from 'react'
import logo from '../images/logo/MagnumLogo.png'
import { useNavigate } from 'react-router-dom'

import insta from '../images/icons/1.svg'
import fb from '../images/icons/2.svg'
import lin from '../images/icons/3.svg'

function Footer() {
  const navigate = useNavigate()

  return (
    <>
      <div className="f1">
        <div className="f1_in">
          <div className="f11">
            <div className="f111">Explore</div>

            <div className="f112" onClick={() => navigate('/BPO')}>
              Business Process Outsourcing
            </div>
            <div className="f112" onClick={() => navigate('/KPO')}>
              Knowledge Process Outsourcing
            </div>
            <div className="f112" onClick={() => navigate('/OperationAndMain')}>
              Operations & Maintanance
            </div>
            <div className="f112" onClick={() => navigate('/Utility')}>
              Utility Management
            </div>
            <div className="f112" onClick={() => navigate('/Security')}>
              Security
            </div>
            <div className="f112" onClick={() => navigate('/ITES')}>
              ITES
            </div>

            <div className="f_icons">
              <a href="https://www.instagram.com/group__magnum/">
                <img src={insta} />
              </a>
              <a href="https://www.facebook.com/magnumgroupbhopal">
                <img src={fb} />
              </a>
              <a href="https://in.linkedin.com/company/magnum-groupbhopal">
                <img src={lin} />
              </a>
            </div>
          </div>
          <div className="f11">
            <div className="f111">Head Office</div>

            <div className="f122">
              Kalaniketan - I, Vaishali Nagar, Kotra Sultanabad, Bhopal - 462003
              (M.P.) India
            </div>

            <div className="f111">Address</div>

            <div className="f122">
              MSD (I) Pvt. Ltd, Ground & Fourth Floor, Chinar Incube Business
              Center, Chinar Fortune City, Hoshangabad Road, Bhopal - 462026
              (M.P.) India
            </div>
          </div>
        </div>
      </div>

      <div className="f2">
  <div className="f1_in">
    <div
      className="f21"
      style={{ marginBottom: '10px' }} // Adds space between the two f21 divs
    >
      <a
        href="https://magnumgroup.co.in/pdf/Privacy_policy.pdf"
        style={{ color: '#FFFFFF', marginRight: '10px' }}
        target="_blank"
      >
        Privacy Policy
      </a>
      
      <a
        href="https://magnumgroup.co.in/pdf/Grievance_Policy.pdf"
        style={{ color: '#FFFFFF', marginRight: '10px' }}
        target="_blank"
      >
        Grievance Policy
      </a>
      
      <a
        href="sitemap.xml"
        style={{ color: '#FFFFFF', marginRight: '10px' }}
        target="_blank"
      >
        Site Map
      </a>
      
      <a
        href="https://magnumgroup.co.in/pdf/Posh_Policy.pdf"
        style={{ color: '#FFFFFF' }}
        target="_blank"
      >
        Posh Policy
      </a>
    </div>
    <div className="f21" style={{ marginBottom: 0 }}>
      Powered by Magnum Group - ISO 9001:2008
    </div>
  </div>
</div>

    </>
  )
}

export default Footer

// 136338541
